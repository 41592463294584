import moment from 'moment';
import { find, isEmpty } from 'lodash';
import { generatePath } from 'react-router';
import Immutable from 'seamless-immutable';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { ELSLoggingService } from '@els/els-ui-common-react';
import { AppConstant, HESI_EXAM } from 'constants/app.constant';
import { EOLSUser, SystemType } from 'models/user-management';
import { PerformancePath } from 'reports/cw/constants/cw.constant';
import { HAD_PATHS } from 'reports/had/constants/had.constant';
import { hadActions } from 'reports/had/redux/ducks/had';
import { fetchCohortsByProgramIds, fetchCohortStudents } from 'reports/had/services/had.service';
import { SH_ROLE } from 'reports/sh/constants/sh.constant';
import { getSHUsersFromEvolveUsername } from 'reports/sh/services/sh.service';
import { fetchCrosswalkUser, fetchExternalUserInfo, fetchAllEolsUserByEolsUserId } from 'services/user-management.service';
import { shActions } from 'reports/sh/redux/ducks/sh';
import { fetchCatalogs } from 'reports/ps/services/ps.service';

const initialState = Immutable({
  actionsInProgress: [],
  breadcrumbBackTitle: '',
  loggedInUser: null,
  evolveUser: null,
  userRole: '',
  courses: [],
  cwCourses: [],
  selectedCourse: null,
  courseSectionRoles: [],
  appLoading: false,
  evolveUserLoading: false,
  evolveUserHasBeenCalled: false,
  eaqTopicMappedHesiExam: [],
  eaqTopicMappedHesiExamByCourseId: {},
  eaqTopicMappedHesiExamByISBN: {},
  isEaqTopicMappedHesiExamLoading: false,
  isBackToPreviousAppIconShown: false,
  redirectUrl: null,
  eaqTopicPerformanceUrl: null,
  linkId: null,
  featureFlags: [],
  isLaunchedFromAnotherApp: false,
  isFeatureFlagsLoading: true,
  isCWLinkHADAndEHRChecking: false,
  isCWBentoBoxLinkShow: false,
  isCWBentoBoxLinkLoading: false,
  isHADStudentDetailLinkShow: false,
  isCWStudentDetailLinkShow: false,
  isHADStudentDetailLinkFetching: true,
  isCWStudentDetailLinkFetching: true,
  hadStudentDetailLink: '',
  cwStudentDetailLink: '',
  isCWSubNavInEHRPageShow: false,
  appLinkOutPostBody: null,
  userId: null,
  bentoBoxShown: true,
  evolveUsername: null,
  isEhrReportingEnabled: false,
  isNclexReportingEnabled: false,
  isProgramSolutionsEnabled: false,
  selectedExpandRowId: null,
  toggleExpandRowId: null,
  allEolsUsers: null,
  catalog: [],
  assignments: [],
  isAllAADataCollected: false,
  customizationScore: null
});

const setAwait = createAction('APP/AWAIT');
const cleanAwait = createAction('APP/AWAIT_CLEAN');
const doNothing = createAction('APP/EMPTY_ACTION');
const restoreState = createAction('APP/RESTORE');
const setLoggedInUser = createAction('APP/SET_LOGGED_IN_USER');
const setUserRole = createAction('APP/SET_USER_ROLE');
const setIsbn = createAction('APP/SET_ISBN');
const setCourses = createAction('APP/SET_COURSES');
const setCWCourses = createAction('APP/SET_CW_COURSES');
const setSelectedCourse = createAction('APP/SET_SELECTED_COURSE');
const setCourseSectionRoles = createAction('APP/SET_COURSE_ROLES');
const setAppLoading = createAction('APP/SET_APP_LOADING');
const setEvolveUserLoading = createAction('APP/SET_EVOLVE_USER_LOADING');
const setEAQTopicMappedHesiExam = createAction('APP/SET_EAQ_TOPIC_MAPPED_HESI_EXAM');
const setEAQTopicMappedHesiExamByCourseId = createAction('APP/SET_EAQ_TOPIC_MAPPED_HESI_EXAM_BY_COURSE_ID');
const setEaqTopicMappedHesiExamByISBN = createAction('APP/SET_EAQ_TOPIC_MAPPED_HESI_EXAM_BY_ISBN');
const setIsEAQTopicMappedHesiExamLoading = createAction('APP/SET_IS_EAQ_TOPIC_MAPPED_HESI_EXAM_LOADING');
const setIsBackToPreviousAppIconShown = createAction('APP/SET_IS_BACK_TO_PREVIOUS_APP_ICON_SHOWN');
const setRedirectUrl = createAction('APP/SET_REDIRECT_URL');
const setEaqTopicReportUrl = createAction('APP/SET_EAQ_TOPIC_REPORT_URL');
const setLinkId = createAction('APP/SET_LINK_ID');
const setFeatureFlags = createAction('APP/SET_FEATURE_FLAGS');
const setEvolveUser = createAction('APP/SET_EVOLVE_USER');
const setEvolveUserHasBeenCalled = createAction('APP/EVOLVE_USER_HAS_BEEN_CALLED');
const setIsFeatureFlagsLoading = createAction('APP/SET_IS_FEATURE_FLAGS_LOADING');
const setIsLaunchedFromAnotherApp = createAction('APP/SET_IS_LAUNCHED_FROM_ANOTHER_APP');
const setIsCWLinkHADAndEHRChecking = createAction('APP/SET_IS_CW_LINK_HAD_AND_EHR_CHECKING');
const setIsCWBentoBoxLinkShow = createAction('APP/SET_IS_CW_BENTO_BOX_LINK_SHOW');
const setIsCWBentoBoxLinkLoading = createAction('APP/SET_IS_CW_BENTO_BOX_LINK_LOADING');
const setIsHADStudentDetailLinkShow = createAction('APP/SET_IS_HAD_STUDENT_DETAIL_LINK_SHOW');
const setIsCWStudentDetailLinkShow = createAction('APP/SET_IS_CW_STUDENT_DETAIL_LINK_SHOW');
const setIsHADStudentDetailLinkFetching = createAction('APP/SET_IS_HAD_STUDENT_DETAIL_LINK_FETCHING');
const setIsCWStudentDetailLinkFetching = createAction('APP/SET_IS_CW_STUDENT_DETAIL_LINK_FETCHING');
const setIsCWSubNavInEHRPageShow = createAction('APP/SET_IS_CW_SUB_NAV_IN_EHR_PAGE_SHOW');
const setHADStudentDetailLink = createAction('APP/SET_HAD_STUDENT_DETAIL_LINK');
const setCWStudentDetailLink = createAction('APP/SET_CW_STUDENT_DETAIL_LINK');
const setAppLinkOutPostBody = createAction('APP/SET_APP_LINK_OUT_POST_BODY');
const setUserId = createAction('APP/SET_USER_ID');
const setActiveUserId = createAction('APP/SET_ACTIVE_USER_ID');
const setBentoBoxShown = createAction('APP/SET_BENTO_BOX_SHOWN');
const setEvolveUsername = createAction('APP/SET_EVOLVE_USERNAME');
const setBreadcrumbBackTitle = createAction('APP/SET_BREADCRUMB_BACK_TITLE');
const setIsEhrReportingEnabled = createAction('APP/SET_IS_EHR_REPORTING_ENABLED');
const setIsNclexReportingEnabled = createAction('APP/SET_IS_NCLEX_REPORTING_ENABLED');
const setIsShEnabled = createAction('APP/SET_IS_SH_ENABLED');
const setIsProgramSolutionsEnabled = createAction('APP/SET_IS_PROGRAM_SOLUTIONS_ENABLED');
const setSelectedExpandRowId = createAction('APP/SET_SELECTED_EXPAND_ROW_ID');
const setToggleExpandRowId = createAction('APP/SET_TOGGLE_EXPAND_ROW_ID');
const setAllEolsUsers = createAction('APP/SET_ALL_EOLS_USERS');
const setCatalog = createAction('APP/SET_CATALOG');
const setAssignments = createAction('APP/SET_ASSIGNMENTS');
const setIsAllAADataCollected = createAction('APP/SET_IS_ALL_AA_DATA_COLLECTED');
const setCustomizationScore = createAction('APP/SET_CUSTOMIZATION_SCORE');

const fetchSHInstructorId = (evolveUsername?: string, eolsUserId?: string) => async dispatch => {
  let evolveUsrn = evolveUsername;
  if (!evolveUsrn) {
    const { data } = await fetchCrosswalkUser(eolsUserId, SystemType.EVOLVETYPE);
    evolveUsrn = data.userName;
  }
  const {
    data: { data }
  } = await getSHUsersFromEvolveUsername(evolveUsrn);
  if (data.length) {
    const { id, role } = data[0];
    if (role === SH_ROLE.INSTRUCTOR) {
      dispatch(shActions.setInstructorId(id));
    }
  }
};

export interface HandleIntegrationLoginFlowProps {
  userRole: string;
  linkId: string;
  eolsUsername?: string;
  eolsUser?: EOLSUser;
}

const fetchEvolveUser = (userId: string) => async dispatch => {
  try {
    dispatch(setEvolveUserLoading(true));
    const crosswalkUser = await fetchCrosswalkUser(userId, SystemType.EVOLVETYPE);
    dispatch(setEvolveUser(crosswalkUser.data));
  } catch (err) {
    ELSLoggingService.error('app.ts', `Failed to fetchEvolveUser: ${err.message}`);
  } finally {
    dispatch(setEvolveUserLoading(false));
    // prevent repeated calls if error
    dispatch(setEvolveUserHasBeenCalled(true));
  }
};

const checkStudentHasHADData = (studentId, programs) => async dispatch => {
  try {
    dispatch(setIsHADStudentDetailLinkFetching(true));
    dispatch(setIsHADStudentDetailLinkShow(true));
    const { programId } = programs[0];
    const [cohortsByProgramIdsRes, crosswalkUserRes] = await Promise.all([fetchCohortsByProgramIds([programId]), fetchCrosswalkUser(studentId, SystemType.EVOLVETYPE)]);
    const cohort = cohortsByProgramIdsRes.data?.sort((a, b) => moment(a.cohortGraduationDate).diff(moment(b.cohortGraduationDate)))?.pop();
    const { cohortId } = cohort;
    const evolveUserName = crosswalkUserRes.data?.userName;
    const isStudentHasHADData = await fetchCohortStudents(cohortId).then(res => res.data.filter(student => student?.evolveUserName === evolveUserName).length);
    if (isStudentHasHADData) {
      const path = generatePath(HAD_PATHS.studentDetail, { cohortId: cohortId.toString(), evolveUserName });
      const originPath = { pathname: generatePath(HAD_PATHS.cohortDetail, { cohortId: cohortId.toString() }), hash: 'byStudent' };
      dispatch(setIsHADStudentDetailLinkShow(true));
      dispatch(setHADStudentDetailLink(path));
      dispatch(hadActions.setStudentDetailOriginPath(originPath));
      dispatch(hadActions.setSelectedCohort(cohort));
      dispatch(hadActions.setProgramId(programId));
      dispatch(hadActions.setIsStudentMultipleCohortEnabled(true));
      await dispatch(hadActions.fetchCategoryGroupListData(cohortId));
    } else {
      dispatch(setIsHADStudentDetailLinkShow(false));
      dispatch(setHADStudentDetailLink(''));
    }
  } catch (e) {
    dispatch(setIsHADStudentDetailLinkShow(false));
    dispatch(setHADStudentDetailLink(''));
    ELSLoggingService.error('app.ts', `checkStudentHasHADData: ${e.message}`);
  } finally {
    dispatch(setIsHADStudentDetailLinkFetching(false));
  }
};

export const checkStudentHasCWData = (evolveUsername: string) => async dispatch => {
  try {
    dispatch(setIsCWStudentDetailLinkFetching(true));
    dispatch(setIsCWStudentDetailLinkShow(true));
    const eolsUser = await fetchExternalUserInfo(evolveUsername);
    const userId = eolsUser?.data?.id?.toString();
    const response = await fetchAllEolsUserByEolsUserId(userId, SystemType.EVOLVETYPE);
    const allEolsUsersData = response.data;
    const allStudentEolsIds = allEolsUsersData.flatMap(user => user.id);
    const allStudentCWCourses = allEolsUsersData.flatMap(user => user.courseSections).filter(course => course.entitlements.length > 0 && course.gradeSyncVersion !== null);
    if (allStudentCWCourses) {
      dispatch(setCWCourses(allStudentCWCourses));
      dispatch(setIsCWStudentDetailLinkShow(true));
      // use the first eols id with entitlements so there is a default link to Sherpath student detail
      dispatch(setCWStudentDetailLink(generatePath(PerformancePath.studentDetail, { studentId: allStudentEolsIds[0].toString() })));
    } else {
      dispatch(setIsCWStudentDetailLinkShow(false));
      dispatch(setCWStudentDetailLink(''));
    }
  } catch (e) {
    dispatch(setIsCWStudentDetailLinkShow(false));
    dispatch(setCWStudentDetailLink(''));
  } finally {
    dispatch(setIsCWStudentDetailLinkFetching(false));
  }
};

export const checkEHREnabled = (courseSectionId?: number) => async (dispatch, getState) => {
  try {
    const appState = getState()[AppConstant.reduxResources.APP_STATE];
    const { courses = [] } = appState;
    const { courseSectionInfo = {} } = getState()[AppConstant.reduxResources.COURSES_STATE];
    const { entitlements } = isEmpty(courseSectionInfo) ? find(courses, { id: courseSectionId }) : courseSectionInfo;
    if (entitlements && entitlements.length > 0) {
      const isbns = entitlements.map(etm => etm.isbn);
      const { data } = await fetchCatalogs(isbns, HESI_EXAM);
      if (data && data?.catalog?.data?.length > 0) {
        dispatch(setIsEhrReportingEnabled(true));
      } else {
        dispatch(setIsEhrReportingEnabled(false));
      }
    }
  } catch (e) {
    ELSLoggingService.error('app.ts', `checkIsEHREnable: ${e.message}`);
    dispatch(setIsEhrReportingEnabled(false));
  }
};

const actions = {
  setAwait,
  checkStudentHasHADData,
  checkStudentHasCWData,
  checkEHREnabled,
  cleanAwait,
  doNothing,
  restoreState,
  setLoggedInUser,
  setUserRole,
  setIsbn,
  setCourses,
  setCWCourses,
  setSelectedCourse,
  setCourseSectionRoles,
  setAppLoading,
  setEvolveUserLoading,
  setEvolveUserHasBeenCalled,
  setEAQTopicMappedHesiExam,
  setEAQTopicMappedHesiExamByCourseId,
  setEaqTopicMappedHesiExamByISBN,
  setIsEAQTopicMappedHesiExamLoading,
  setIsBackToPreviousAppIconShown,
  setRedirectUrl,
  setEaqTopicReportUrl,
  setLinkId,
  setFeatureFlags,
  setEvolveUser,
  setIsLaunchedFromAnotherApp,
  setIsFeatureFlagsLoading,
  setIsCWLinkHADAndEHRChecking,
  setIsCWBentoBoxLinkShow,
  setIsCWBentoBoxLinkLoading,
  setIsHADStudentDetailLinkShow,
  setIsCWStudentDetailLinkShow,
  setIsCWSubNavInEHRPageShow,
  setIsHADStudentDetailLinkFetching,
  setIsCWStudentDetailLinkFetching,
  setHADStudentDetailLink,
  setCWStudentDetailLink,
  setAppLinkOutPostBody,
  fetchEvolveUser,
  setUserId,
  setActiveUserId,
  setBentoBoxShown,
  setEvolveUsername,
  fetchSHInstructorId,
  setBreadcrumbBackTitle,
  setIsEhrReportingEnabled,
  setIsNclexReportingEnabled,
  setIsShEnabled,
  setIsProgramSolutionsEnabled,
  setSelectedExpandRowId,
  setToggleExpandRowId,
  setAllEolsUsers,
  setCatalog,
  setAssignments,
  setIsAllAADataCollected,
  setCustomizationScore
};
const reducer = handleActions(
  {
    [actions.setAwait]: (state, { payload: { isLoading }, meta }) => {
      let { actionsInProgress } = state;
      if (isLoading) {
        actionsInProgress = [...actionsInProgress, meta];
      } else {
        const index = actionsInProgress.findIndex(item => item.actionType === meta.actionType);
        actionsInProgress = [...actionsInProgress.slice(0, index), ...actionsInProgress.slice(index + 1)];
      }

      return state.set('actionsInProgress', actionsInProgress);
    },
    [actions.cleanAwait]: state => state.set('actionsInProgress', []),
    [actions.restoreState]: (state, { payload }) => state.merge(payload),
    [actions.setLoggedInUser]: (state, { payload }) => state.set('loggedInUser', payload),
    [actions.setUserRole]: (state, { payload }) => state.set('userRole', payload),
    [actions.setIsbn]: (state, { payload }) => state.set('isbn', payload),
    [actions.setCourses]: (state, { payload }) => state.set('courses', payload),
    [actions.setCWCourses]: (state, { payload }) => state.set('cwCourses', payload),
    [actions.setSelectedCourse]: (state, { payload }) => state.set('selectedCourse', payload),
    [actions.setCourseSectionRoles]: (state, { payload }) => Immutable.set(state, 'courseSectionRoles', payload.data),
    [actions.setAppLoading]: (state, { payload }) => Immutable.set(state, 'appLoading', payload),
    [actions.setEvolveUserLoading]: (state, { payload }) => Immutable.set(state, 'evolveUserLoading', payload),
    [actions.setEvolveUserHasBeenCalled]: (state, { payload }) => Immutable.set(state, 'evolveUserHasBeenCalled', payload),
    [actions.setEAQTopicMappedHesiExamByCourseId]: (state, { payload }) => Immutable.set(state, 'eaqTopicMappedHesiExamByCourseId', payload),
    [actions.setEaqTopicMappedHesiExamByISBN]: (state, { payload }) => Immutable.set(state, 'eaqTopicMappedHesiExamByISBN', payload),
    [actions.setEAQTopicMappedHesiExam]: (state, { payload }) => Immutable.set(state, 'eaqTopicMappedHesiExam', payload),
    [actions.setIsEAQTopicMappedHesiExamLoading]: (state, { payload }) => Immutable.set(state, 'isEaqTopicMappedHesiExamLoading', payload),
    [actions.setIsBackToPreviousAppIconShown]: (state, { payload }) => Immutable.set(state, 'isBackToPreviousAppIconShown', payload),
    [actions.setRedirectUrl]: (state, { payload }) => Immutable.set(state, 'redirectUrl', payload),
    [actions.setEaqTopicReportUrl]: (state, { payload }) => Immutable.set(state, 'eaqTopicReportUrl', payload),
    [actions.setLinkId]: (state, { payload }) => Immutable.set(state, 'linkId', payload),
    [actions.setFeatureFlags]: (state, { payload }) => Immutable.set(state, 'featureFlags', payload),
    [actions.setIsFeatureFlagsLoading]: (state, { payload }) => Immutable.set(state, 'isFeatureFlagsLoading', payload),
    [actions.setEvolveUser]: (state, { payload }) => Immutable.set(state, 'evolveUser', payload),
    [actions.setIsLaunchedFromAnotherApp]: (state, { payload }) => Immutable.set(state, 'isLaunchedFromAnotherApp', payload),
    [actions.setIsCWBentoBoxLinkShow]: (state, { payload }) => Immutable.set(state, 'isCWBentoBoxLinkShow', payload),
    [actions.setIsCWBentoBoxLinkLoading]: (state, { payload }) => Immutable.set(state, 'isCWBentoBoxLinkLoading', payload),
    [actions.setIsCWLinkHADAndEHRChecking]: (state, { payload }) => Immutable.set(state, 'isCWLinkHADAndEHRChecking', payload),
    [actions.setIsHADStudentDetailLinkShow]: (state, { payload }) => Immutable.set(state, 'isHADStudentDetailLinkShow', payload),
    [actions.setIsCWStudentDetailLinkShow]: (state, { payload }) => Immutable.set(state, 'isCWStudentDetailLinkShow', payload),
    [actions.setIsHADStudentDetailLinkFetching]: (state, { payload }) => Immutable.set(state, 'isHADStudentDetailLinkFetching', payload),
    [actions.setIsCWStudentDetailLinkFetching]: (state, { payload }) => Immutable.set(state, 'isCWStudentDetailLinkFetching', payload),
    [actions.setIsCWSubNavInEHRPageShow]: (state, { payload }) => Immutable.set(state, 'isCWSubNavInEHRPageShow', payload),
    [actions.setHADStudentDetailLink]: (state, { payload }) => Immutable.set(state, 'hadStudentDetailLink', payload),
    [actions.setCWStudentDetailLink]: (state, { payload }) => Immutable.set(state, 'cwStudentDetailLink', payload),
    [actions.setAppLinkOutPostBody]: (state, { payload }) => Immutable.set(state, 'appLinkOutPostBody', payload),
    [actions.setUserId]: (state, { payload }) => Immutable.set(state, 'userId', payload),
    [actions.setActiveUserId]: (state, { payload }) => Immutable.set(state, 'userId', payload),
    [actions.setBentoBoxShown]: (state, { payload }) => Immutable.set(state, 'bentoBoxShown', payload),
    [actions.setEvolveUsername]: (state, { payload }) => Immutable.set(state, 'evolveUsername', payload),
    [actions.setBreadcrumbBackTitle]: (state, { payload }) => Immutable.set(state, 'breadcrumbBackTitle', payload),
    [actions.setIsEhrReportingEnabled]: (state, { payload }) => Immutable.set(state, 'isEhrReportingEnabled', payload),
    [actions.setIsNclexReportingEnabled]: (state, { payload }) => Immutable.set(state, 'isNclexReportingEnabled', payload),
    [actions.setIsShEnabled]: (state, { payload }) => Immutable.set(state, 'isShEnabled', payload),
    [actions.setIsProgramSolutionsEnabled]: (state, { payload }) => Immutable.set(state, 'isProgramSolutionsEnabled', payload),
    [actions.setSelectedExpandRowId]: (state, { payload }) => Immutable.set(state, 'selectedExpandRowId', payload),
    [actions.setToggleExpandRowId]: (state, { payload }) => Immutable.set(state, 'toggleExpandRowId', payload),
    [actions.setAllEolsUsers]: (state, { payload }) => Immutable.set(state, 'allEolsUsers', payload),
    [actions.setCatalog]: (state, { payload }) => Immutable.set(state, 'catalog', payload),
    [actions.setAssignments]: (state, { payload }) => Immutable.set(state, 'assignments', payload),
    [actions.setIsAllAADataCollected]: (state, { payload }) => Immutable.set(state, 'isAllAADataCollected', payload),
    [actions.setCustomizationScore]: (state, { payload }) => Immutable.set(state, 'customizationScore', payload)
  },
  initialState
);

const getAppState = state => state[AppConstant.reduxResources.APP_STATE];

export const getUserId = state => state.getIn(['loggedInUser', 'userId']) || state.getIn(['loggedInUser', 'id']) || state.userId;

const selectors = {
  getAwaitingStatus: createSelector(getAppState, state => state.actionsInProgress.length > 0),
  getLoggedInUser: createSelector(getAppState, state => state.loggedInUser),
  getEvolveUser: createSelector(getAppState, state => state.evolveUser),
  getUserId: createSelector(getAppState, getUserId),
  getActiveUserId: createSelector(getAppState, getUserId),
  getUserRole: createSelector(getAppState, state => state.userRole),
  getIsbn: createSelector(getAppState, state => state.isbn),
  getCourses: createSelector(getAppState, state => state.courses),
  getCWCourses: createSelector(getAppState, state => state.cwCourses),
  getSelectedCourse: createSelector(getAppState, state => state.selectedCourse),
  getCourseSectionRoles: createSelector(getAppState, state => state.courseSectionRoles),
  getAppLoading: createSelector(getAppState, state => state.appLoading),
  getEvolveUserLoading: createSelector(getAppState, state => state.evolveUserLoading),
  getEvolveUserHasBeenCalled: createSelector(getAppState, state => state.evolveUserHasBeenCalled),
  getEAQTopicMappedHesiExam: createSelector(getAppState, state => state.eaqTopicMappedHesiExam),
  getEAQTopicMappedHesiExamByCourseId: createSelector(getAppState, state => state.eaqTopicMappedHesiExamByCourseId),
  getIsEAQTopicMappedHesiExamLoading: createSelector(getAppState, state => state.isEaqTopicMappedHesiExamLoading),
  getIsBackToPreviousAppIconShown: createSelector(getAppState, state => state.isBackToPreviousAppIconShown),
  getRedirectUrl: createSelector(getAppState, state => state.redirectUrl),
  getEaqTopicReportUrl: createSelector(getAppState, state => state.eaqTopicReportUrl),
  getLinkId: createSelector(getAppState, state => state.linkId),
  getFeatureFlags: createSelector(getAppState, state => state.featureFlags),
  getIsFeatureFlagsLoading: createSelector(getAppState, state => state.isFeatureFlagsLoading),
  getIsLaunchedFromAnotherApp: createSelector(getAppState, state => state.isLaunchedFromAnotherApp),
  getIsCWLinkHADAndEHRChecking: createSelector(getAppState, state => state.isCWLinkHADAndEHRChecking),
  getIsCWBentoBoxLinkShow: createSelector(getAppState, state => state.isCWBentoBoxLinkShow),
  getIsCWBentoBoxLinkLoading: createSelector(getAppState, state => state.isCWBentoBoxLinkLoading),
  getIsHADStudentDetailLinkShow: createSelector(getAppState, state => state.isHADStudentDetailLinkShow),
  getIsCWStudentDetailLinkShow: createSelector(getAppState, state => state.isCWStudentDetailLinkShow),
  getIsCWSubNavInEHRPageShow: createSelector(getAppState, state => state.isCWSubNavInEHRPageShow),
  getIsHADStudentDetailLinkFetching: createSelector(getAppState, state => state.isHADStudentDetailLinkFetching),
  getIsCWStudentDetailLinkFetching: createSelector(getAppState, state => state.isCWStudentDetailLinkFetching),
  getHADStudentDetailLink: createSelector(getAppState, state => state.hadStudentDetailLink),
  getCWStudentDetailLink: createSelector(getAppState, state => state.cwStudentDetailLink),
  getAppLinkOutPostBody: createSelector(getAppState, state => state.appLinkOutPostBody),
  getBentoBoxShown: createSelector(getAppState, state => state.bentoBoxShown),
  getEvolveUsername: createSelector(getAppState, state => state.evolveUsername),
  getBreadcrumbBackTitle: createSelector(getAppState, state => state.breadcrumbBackTitle),
  getIsEhrReportingEnabled: createSelector(getAppState, state => state.isEhrReportingEnabled),
  getIsNclexReportingEnabled: createSelector(getAppState, state => state.isNclexReportingEnabled),
  getIsShEnabled: createSelector(getAppState, state => state.isShEnabled),
  getIsProgramSolutionsEnabled: createSelector(getAppState, state => state.isProgramSolutionsEnabled),
  getSelectedExpandRowId: createSelector(getAppState, state => state.selectedExpandRowId),
  getEaqTopicMappedHesiExamByISBN: createSelector(getAppState, state => state.eaqTopicMappedHesiExamByISBN),
  getAllEolsUsers: createSelector(getAppState, state => state.allEolsUsers),
  getCatalog: createSelector(getAppState, state => state.catalog),
  getAssignments: createSelector(getAppState, state => state.assignments),
  getToggleExpandRowId: createSelector(getAppState, state => state.toggleExpandRowId),
  getIsAllAADataCollected: createSelector(getAppState, state => state.isAllAADataCollected),
  getCustomizationScore: createSelector(getAppState, state => state.customizationScore)
};

export { actions as appActions, reducer as appReducer, selectors as appSelectors };
export { initialState as appInitialState };
